<template>
	<div class="opacity-page">
		<div class="header-filter">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('admin_users.search-by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
					<div class="actions-page mobile">
						<div class="last-update-text">{{ $t('admin_users.last_update_sync') }} <div class="date">{{ last_updated | moment('DD MMM YYYY HH:mm') }}</div></div>

						<button class="btn-tbf center blue" id="buttonSync" @click="syncList">
							<div class="loader"></div>
							<div class="text">{{ $t('admin_users.sync') }}</div>
						</button>
						<button id="downloadExcel" class="btn-tbf completed center only-icon" @click="donwloadXLS">
							<div class="loader-spin" v-if="loadingExportXLS"></div>
							<icon-download class="icon" v-else/>
						</button>

						<div class="actions actions-dropdown">
							<div class="actions-page mobile">
								<button v-if="$resize && $mq.above(600)" class="btn-tbf grey filter-btn" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
									<div class="counter" v-bind:class="{active: queryFiltersCount > 0}">{{ queryFiltersCount }}</div>
									<span class="text">{{ $t('admin_users.filters.title') }}</span>
									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<button v-else-if="$resize && $mq.below(599)" class="btn-tbf grey filter-btn-mobile" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
									<div class="icon settings"><icon-settings /></div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
			<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.below(1120)}]" v-if="extendFilters">
				<div class="space-left"></div>
				<div class="content users">
					<div class="filter-dropdown">
						<div class="label">{{ $t('admin_users.objectives') }}</div>

						<div class="dropdown group-dropdown">
							<button class="btn-tbf white" id="dropdownFilterObjStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedInstanceStatusObjectives.length">{{ $t('admin_users.filters.by_status') }}</span>
								<span class="text" v-else-if="selectedInstanceStatusObjectives.length == 1">Status: {{ $t(`admin_users.status_soft.${filterInstanceStatus.find(el => el == selectedInstanceStatusObjectives[0])}`) }}</span>
								<span class="text" v-else>{{ selectedInstanceStatusObjectives.length }} {{ $t('admin_users.filters.status_selected') }}</span>

								<div class="icon-filter" v-if="!selectedInstanceStatusObjectives.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedInstanceStatusObjectives')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilterObjStatus">

								<template v-for="status in filterInstanceStatus">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedInstanceStatusObjectives', status)" v-bind:class="{active: selectedInstanceStatusObjectives.includes(status)}" :key="'filter-status-' + status">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t(`admin_users.status_soft.${status}`) }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>

						<div class="dropdown group-dropdown">
							<button class="btn-tbf white mt-second-filter" id="dropdownFilterObjPackage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedPackagesObjectives.length">{{ $t('admin_users.filters.by_packages') }}</span>
								<span class="text" v-else-if="selectedPackagesObjectives.length == 1">Abonament: {{ filterPackagesObjectives.find(el => el == selectedPackagesObjectives[0]) }}</span>
								<span class="text" v-else>{{ selectedPackagesObjectives.length }} {{ $t('admin_users.filters.status_selected') }}</span>

								<div class="icon-filter" v-if="!selectedPackagesObjectives.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedPackagesObjectives')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilterObjPackage">
								<template v-for="package_item in filterPackagesObjectives">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedPackagesObjectives', package_item)" v-bind:class="{active: selectedPackagesObjectives.includes(package_item)}" :key="'filter-package-obj-' + package_item">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ package_item }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>

					<div class="filter-dropdown">
						<div class="label">{{ $t('admin_users.procedures') }}</div>

						<div class="dropdown group-dropdown">
							<button class="btn-tbf white" id="dropdownFilterProcedures" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedInstanceStatusProcedures.length">{{ $t('admin_users.filters.by_status') }}</span>
								<span class="text" v-else-if="selectedInstanceStatusProcedures.length == 1">{{ $t(`admin_users.status_soft.${filterInstanceStatus.find(el => el == selectedInstanceStatusProcedures[0])}`) }}</span>
								<span class="text" v-else>{{ selectedInstanceStatusProcedures.length }} {{ $t('admin_users.filters.status_selected') }}</span>

								<div class="icon-filter" v-if="!selectedInstanceStatusProcedures.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedInstanceStatusProcedures')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilterProcedures">

								<template v-for="status in filterInstanceStatus">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedInstanceStatusProcedures', status)" v-bind:class="{active: selectedInstanceStatusProcedures.includes(status)}" :key="'filter-status-' + status">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t(`admin_users.status_soft.${status}`) }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>

						<div class="dropdown group-dropdown">
							<button class="btn-tbf white mt-second-filter" id="dropdownFilterProcPackage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedPackagesProcedures.length">{{ $t('admin_users.filters.by_packages') }}</span>
								<span class="text" v-else-if="selectedPackagesProcedures.length == 1">Abonament: {{ filterPackagesProcedures.find(el => el == selectedPackagesProcedures[0]) }}</span>
								<span class="text" v-else>{{ selectedPackagesProcedures.length }} {{ $t('admin_users.filters.status_selected') }}</span>

								<div class="icon-filter" v-if="!selectedPackagesProcedures.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedPackagesProcedures')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilterProcPackage">

								<template v-for="package_item in filterPackagesProcedures">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedPackagesProcedures', package_item)" v-bind:class="{active: selectedPackagesProcedures.includes(package_item)}" :key="'filter-package-proc-' + package_item">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ package_item }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>

					<div class="filter-dropdown">
						<div class="label">{{ $t('admin_users.organigram') }}</div>

						<div class="dropdown group-dropdown">
							<button class="btn-tbf white" id="dropdownFilterOrg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedInstanceStatusOrganigram.length">{{ $t('admin_users.filters.by_status') }}</span>
								<span class="text" v-else-if="selectedInstanceStatusOrganigram.length == 1">{{ $t(`admin_users.status_soft.${filterInstanceStatus.find(el => el == selectedInstanceStatusOrganigram[0])}`) }}</span>
								<span class="text" v-else>{{ selectedInstanceStatusOrganigram.length }} {{ $t('admin_users.filters.status_selected') }}</span>

								<div class="icon-filter" v-if="!selectedInstanceStatusOrganigram.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedInstanceStatusOrganigram')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilterOrg">

								<template v-for="status in filterInstanceStatus">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedInstanceStatusOrganigram', status)" v-bind:class="{active: selectedInstanceStatusOrganigram.includes(status)}" :key="'filter-status-' + status">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t(`admin_users.status_soft.${status}`) }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>

						<div class="dropdown group-dropdown">
							<button class="btn-tbf white mt-second-filter" id="dropdownFilterOrgPackage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedPackagesOrganigram.length">{{ $t('admin_users.filters.by_packages') }}</span>
								<span class="text" v-else-if="selectedPackagesOrganigram.length == 1">Abonament: {{ filterPackagesOrganigram.find(el => el == selectedPackagesOrganigram[0]) }}</span>
								<span class="text" v-else>{{ selectedPackagesOrganigram.length }} {{ $t('admin_users.filters.status_selected') }}</span>

								<div class="icon-filter" v-if="!selectedPackagesOrganigram.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedPackagesOrganigram')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilterOrgPackage">

								<template v-for="package_item in filterPackagesOrganigram">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedPackagesOrganigram', package_item)" v-bind:class="{active: selectedPackagesOrganigram.includes(package_item)}" :key="'filter-package-org-' + package_item">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ package_item }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>

					<div class="filter-dropdown">
						<div class="dropdown group-dropdown">
							<button class="btn-tbf white no-label" id="dropdownFilterOrgPackage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text">Dupa data sfarsit abonament</span>

								<div class="icon-filter"><icon-arrow /></div>
							</button>
							<!-- @click="filterDateEndingSubscription = true" -->
							<div class="dropdown-menu" aria-labelledby="dropdownFilterOrgPackage">
								<div class="dropdown-item" @click="selectOptionFilter('filterDateEndingSubscription', true)"  v-bind:class="{active: filterDateEndingSubscription }">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">Da</span>
									</div>
								</div>
								<div class="dropdown-item" @click="selectOptionFilter('filterDateEndingSubscription', false)" v-bind:class="{active: !filterDateEndingSubscription }">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">Nu</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash v-if="$resize && $mq.above(1121)"/>
						<div v-else-if="$resize && $mq.below(1120)">{{$t('admin_users.filters.clear_all')}}</div>
					</button>
				</div>
				<div class="space-right"></div>
			</div>
		</div>

		<template v-if="loadedList">

			<div class="total-entries row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="count-entries">{{ $t('admin_users.filters.total_entries') }}<span class="number">{{ total_informations }}</span></div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="data-list list-users-report" v-if="users.length">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-user" v-if="$resize && $mq.above(501)"></div>
						<div class="column-filter column-master_inst_id">
							<div class="text">{{ $t('admin_users.mast_inst_id') }}</div>
						</div>
						<div class="column-filter column-name">
							<div class="text">{{ $t('admin_users.name') }}</div>
						</div>
						<div class="column-filter column-phone">
							<div class="text">{{ $t('admin_users.phone') }}</div>
						</div>
						<div class="column-filter column-objectives">
							<div class="text">{{ $t('admin_users.objectives') }}</div>
						</div>
						<div class="column-filter column-procedures">
							<div class="text">{{ $t('admin_users.procedures') }}</div>
						</div>
						<div class="column-filter column-organigram">
							<div class="text">{{ $t('admin_users.organigram') }}</div>
						</div>
						<div class="column-filter column-created_at">
							<div class="text">{{ $t('admin_users.created_at') }}</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="user in users">
					<div class="row-space-tbf row-list-item" :key="user.id">
						<div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-simple-text column-master_inst_id">
								<div class="text">{{ user.master_instance_id }}</div>
							</div>
							<div class="column-name-desc column-name link" @click="showModal('show_instance', user)">
								<div class="name">{{ user.name }}</div>
								<div class="description">{{ user.email }}</div>
							</div>
							<div class="column-simple-text column-phone">
								<div class="text">{{ user.phone }}</div>
							</div>
							<div class="column-name-desc column-objectives" v-if="user.objective">
								<div class="name">{{ user.objective.package }}</div>
								<div :class="`description status-${user.objective.status}`">{{ user.objective.status ? $t(`admin_users.status_soft.${user.objective.status}`) : "" }}</div>
								<div class="description">{{ user.objective.stripe }}</div>
								<div class="description">{{ user.objective.voucher ? `Voucher: ${user.objective.voucher}` : '' }}</div>
								<div class="description" v-if="user.objective.cui != null">{{ $t('admin_users.cui')  }} {{ user.objective.cui }}</div>
							</div>
							<div class="column-name-desc column-objectives" v-else></div>

							<div class="column-name-desc column-procedures" v-if="user.procedure">
								<div class="name">{{ user.procedure.package }}</div>
								<div :class="`description status-${user.procedure.status}`">{{ user.procedure.status ? $t(`admin_users.status_soft.${user.procedure.status}`) : "" }}</div>
								<div class="description">{{ user.procedure.stripe }}</div>
								<div class="description">{{ user.procedure.voucher ? `Voucher: ${user.procedure.voucher}` : '' }}</div>
								<div class="description" v-if="user.procedure.cui != null">{{ $t('admin_users.cui')  }} {{ user.procedure.cui }}</div>
							</div>
							<div class="column-name-desc column-procedures" v-else></div>

							<div class="column-name-desc column-organigram" v-if="user.organigram">
								<div class="name">{{ user.organigram.package }}</div>
								<div :class="`description status-${user.organigram.status}`">{{ user.organigram.status ? $t(`admin_users.status_soft.${user.organigram.status}`) : ""}}</div>
								<div class="description">{{ user.organigram.stripe }}</div>
								<div class="description">{{ user.organigram.voucher ? `Voucher: ${user.organigram.voucher}` : '' }}</div>
								<div class="description" v-if="user.organigram.cui != null">{{ $t('admin_users.cui')  }} {{ user.organigram.cui }}</div>
							</div>
							<div class="column-name-desc column-organigram" v-else></div>

							<div class="column-simple-text column-created_at">
								<div class="text">{{ user.created_at | moment('DD MMM YYYY') }}</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<div class="title">{{ $t('admin_users.empty_title')}}</div>
					<div class="description" v-html="$t('admin_users.empty_users_text')"></div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>
		
		<infinite-loading :identifier="infiniteId" @infinite="infiniteUsersHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
    import IconSearch from '../Icons/Search'
    import IconArrow from '../Icons/Arrow'
    import IconTrash from '../Icons/Trash'
    import IconUser from '../Icons/User'
    import IconPeople from '../Icons/People'
	import IconSettings from '../Icons/Settings'
	import IconClose from '../Icons/Close'
	import IconDownload from '../Icons/Download'
    import LoaderItemsList from '../PagesLoaders/ItemsList'
	import InfiniteLoading from 'vue-infinite-loading'

    export default {
    	components: {
            IconSearch,
            IconArrow,
            IconTrash,
            IconUser,
            IconPeople,
			IconSettings,
			IconClose,
           	LoaderItemsList,
			IconDownload,
			InfiniteLoading
        },
        data() {
            return {
            	loaded: false,
				loadedList: false,
            	search_filter: '',
            	users: [],
            	sortBy: '',
            	sortDirection: 'asc',
				page: 0,
				infiniteId: 1,
				last_updated: '',
				total_informations: '',
				queryFiltersCount: 0,
            	extendFilters: true,
				filterInstanceStatus: [ 'active', 'unpaid', 'grace_period', 'new', 'null'],
            	selectedInstanceStatusObjectives: [],
            	selectedInstanceStatusProcedures: [],
            	selectedInstanceStatusOrganigram: [],
            	selectedPackagesObjectives: [],
            	selectedPackagesProcedures: [],
            	selectedPackagesOrganigram: [],
				filterPackagesObjectives: [],
				filterPackagesProcedures: [],
				filterPackagesOrganigram: [],
				loadingExportXLS: false,
				filterDateEndingSubscription: false,
            }
        },
        async mounted(){
        	var refreshIntervalApps = setInterval(() => {
        		if(this.$store.state.packages.list.length){
        			clearInterval(refreshIntervalApps);
        			setTimeout(() => {
        				this.filterPackagesObjectives = this.$store.getters['packages/getBySoft']('objective').packages.map(el => { return el.unique_name })
        				this.filterPackagesProcedures = this.$store.getters['packages/getBySoft']('procedure').packages.map(el => { return el.unique_name })
        				this.filterPackagesOrganigram = this.$store.getters['packages/getBySoft']('organigram').packages.map(el => { return el.unique_name })
        			}, 200)
        		}
        	}, 200);

			this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)

        	await this.checkQueryFilters()

			setTimeout(() => {
				var title = this.$t('navbar.admin_users');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshUsersIndex', () => {
				this.checkQueryFilters()
				this.searchFilterFunct()
			})

			this.$root.$on('syncMiniAdmin', () => {
				axios.get('/overall')
				.then(() => {
					this.checkQueryFilters()
					this.searchFilterFunct()
				})
			})

			this.$root.$on('syncUsers', () => {
				this.syncList();
			})
        },
        watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
        methods: {
			infiniteUsersHandler($state){
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

        		axios.post('overall-informations', paramsCall )
        		.then(({data}) => {
        			this.last_updated = data.data.last_updated
        			this.total_informations = data.data.total_informations

        			if(data.data.overall_informations.length){
        				this.page++
        				this.users.push(...data.data.overall_informations)
						setTimeout(() => { $state.loaded() }, 200)
							if(data.data.overall_informations.length < 20){
								$state.complete()
							}
					}else{
						$state.complete()
					}
					this.loadedList = true
        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		}).finally(() => { 
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
        	},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				if(['status_objectives', 'status_procedures', 'status_organigram' , 'package_objectives', 'package_procedures', 'package_organigram', 'expire_custom_subscription'].some(r=> Object.keys(dataQuery).indexOf(r) >= 0)){
					paramsCall.search_columns = []
				}

				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }

					if(key == 'status_objectives'){ paramsCall.search_columns.push({ soft: 'objective', filter: 'status', value: dataQuery[key].split(',')}) }
					if(key == 'status_procedures'){ paramsCall.search_columns.push({ soft: 'procedure', filter: 'status', value: dataQuery[key].split(',')}) }
					if(key == 'status_organigram'){ paramsCall.search_columns.push({ soft: 'organigram', filter: 'status', value: dataQuery[key].split(',')}) }
					if(key == 'package_objectives'){ paramsCall.search_columns.push({ soft: 'objective', filter: 'package', value: dataQuery[key].split(',')}) }
					if(key == 'package_procedures'){ paramsCall.search_columns.push({ soft: 'procedure', filter: 'package', value: dataQuery[key].split(',')}) }
					if(key == 'package_organigram'){ paramsCall.search_columns.push({ soft: 'organigram', filter: 'package', value: dataQuery[key].split(',')}) }
					if(key == 'expire_custom_subscription'){ paramsCall.search_columns.push({ soft: 'organigram', filter: 'expire_custom_subscription', value: [ dataQuery[key] == 'true' ? 1 : 0 ] }) }
				});
			},
			selectOptionFilter(filter, value) {
				if(filter == 'filterDateEndingSubscription') {
					this[filter] = value
				} else {
					if(typeof value == 'string'){
						if(this[filter].includes(value)){
							this[filter].splice(this[filter].indexOf(value), 1)
						}else{
							this[filter].push(value)
						}
						
					} else {
						if(this[filter].includes(parseInt(value))){
						
							this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
						}else{
							this[filter].push(parseInt(value))
						}
						
					}
				}

				this.addFilterToRouteQuery(filter)
        	},
			addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedInstanceStatusObjectives' || filterName == 'all'){ dataQuery['status_objectives'] = this.selectedInstanceStatusObjectives.toString(); }
				if(filterName == 'selectedInstanceStatusProcedures' || filterName == 'all'){ dataQuery['status_procedures'] = this.selectedInstanceStatusProcedures.toString(); }
				if(filterName == 'selectedInstanceStatusOrganigram' || filterName == 'all'){ dataQuery['status_organigram'] = this.selectedInstanceStatusOrganigram.toString(); }
				if(filterName == 'selectedPackagesObjectives' || filterName == 'all'){ dataQuery['package_objectives'] = this.selectedPackagesObjectives.toString(); }
				if(filterName == 'selectedPackagesProcedures' || filterName == 'all'){ dataQuery['package_procedures'] = this.selectedPackagesProcedures.toString(); }
				if(filterName == 'selectedPackagesOrganigram' || filterName == 'all'){ dataQuery['package_organigram'] = this.selectedPackagesOrganigram.toString(); }
				if(filterName == 'filterDateEndingSubscription' || filterName == 'all'){ dataQuery['expire_custom_subscription'] = this.filterDateEndingSubscription.toString(); }


				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedInstanceStatusObjectives = []
					this.selectedInstanceStatusProcedures = []
					this.selectedInstanceStatusOrganigram = []
					this.selectedPackagesObjectives = []
					this.selectedPackagesProcedures = []
					this.selectedPackagesOrganigram = []
					this.filterDateEndingSubscription = false
				}else{
					this[filter] = []
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }

					if(key == 'status_objectives'){ this.selectedInstanceStatusObjectives = dataQuery[key].split(',') }
					if(key == 'status_procedures'){ this.selectedInstanceStatusProcedures = dataQuery[key].split(',') }
					if(key == 'status_organigram'){ this.selectedInstanceStatusOrganigram = dataQuery[key].split(',') }

					if(key == 'package_objectives'){ this.selectedPackagesObjectives = dataQuery[key].split(',') }
					if(key == 'package_procedures'){ this.selectedPackagesProcedures = dataQuery[key].split(',') }
					if(key == 'package_organigram'){ this.selectedPackagesOrganigram = dataQuery[key].split(',') }

					if(key == 'expire_custom_subscription'){ this.filterDateEndingSubscription = dataQuery[key] }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;

				this.searchFilterFunct()
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.users = []

				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			},
			syncList(){
				var buttonName = `buttonSync`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.get('/overall')
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('admin_users.sync')
							btnSubmit.disabled = false

							this.searchFilterFunct()
						}, 300)
					})
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('admin_users.sync')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
			},
			showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			},
			donwloadXLS() {
				var buttonExcel = document.getElementById('downloadExcel');
				buttonExcel.disabled = true;
				this.loadingExportXLS = true;

				axios.get('/reports/overall-download', 
				{
                	responseType: 'blob'
				})
				.then((response) => {
					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = 'Overall TBF.xlsx';

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
				.finally(() => {
					buttonExcel.disabled = false;
					this.loadingExportXLS = false;
				})
            }
        }
    }

	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
    .last-update-text{
    	align-self: center;
    	margin-right: 20px;
    	font-size: 13px;
    	.date{
    		margin-top: 3px;
    		text-transform: capitalize;
    		font-weight: 500;
    	}
    }

    .total-entries{
    	padding: 15px 0;
    	.count-entries{
    		font-size: 14px;
    		.number{
    			font-weight: 500;
    		}
    	}
    }

    .mt-second-filter{
    	margin-top: 15px;
    }

    .list-users-report{
    	.row-list-item{
    		&:hover{
    			.content{
    				.column-name{
    					.name{
    						font-weight: 700;
    					}
    				}
    			}
    		}
    	}
    	.column-name{
    		flex: 1 1 auto;
    		&.link{
    			cursor: pointer;
    		}
    	}
    	.column-master_inst_id{
    		flex: 0 0 80px;
    	}
    	.column-phone{
    		flex: 0 0 160px;
    	}
    	.column-objectives,
    	.column-procedures,
    	.column-organigram{
    		flex: 0 0 200px;

    		.description{
	    		&.status-active{
	    			color: #03ae58 !important;
	    		}
	    		&.status-grace_period{
	    			color: $yellow !important;
	    		}
	    		&.status-unpaid{
	    			color: $red !important;
	    		}
	    		&.status-new{
	    			color: $blue !important;
	    		}
    		}
    	}
    	.column-created_at{
    		flex: 0 0 100px;
    	}
    }
</style>